const apiUrl =
  process.env.REACT_APP_API_BASE_URL || "https://api.semanticpulse.no"

export const streamFromLLM = async function* (authorizationToken, chat) {
  const systemMessage = {
    role: "system",
    text: `Vær en profesjonell lege som gjennomfører en medikamentgjennomgang. Du vil få informasjon om pasientens alder, kjønn, diagnoseoversikt, observasjonsliste med datoer, og en liste over pasientens medisiner.

Basert på denne informasjonen, utfør en medikamentgjennomgang og vurder om det er spesielle hensyn med tanke på pasientens diagnoser, observasjoner, alder og potensielle interaksjoner mellom medisinene pasienten bruker.

Hold det svært kort og konsist, og presenter medisinene i en liste med tilhørende vurdering. Bruk en logisk struktur og vær tydelig. Ikke inkluder mer informasjon enn nødvendig.

Svar alltid på norsk.

Følg denne strukturen:

Medikament 1. Vurdering av medikamentet.
Medikament 2. Vurdering av medikamentet.
Medikament 3. Vurdering av medikamentet.

Eksempel:

- Diazepam 5 mg: Unngå grunnet pasientens alder og risiko for sedasjon og fall.
- Warfarin 5 mg: Kontraindisert på grunn av pasientens historie med hjerneblødning.
- Pramipexole dihydrochloride monohydrate 88 mcg: Bruk med forsiktighet på grunn av pasientens lave GFR.
- Amitriptyline 50 mg x 1 (Dose: 45): Bruk med forsiktighet hos eldre grunnet økt risiko for kognitiv svikt og fall.
- Diazepam 5 mg x 1 (Dose: 500): Bør unngås hos eldre grunnet økt risiko for sedasjon og fall. Kan påvirke nyrefunksjonen og bør doseres nøye.
- Paracetamol 1 g x 1 (Dose: 1 g): Kan brukes trygt hos eldre hvis dosert riktig.
- Pramipexole dihydrochloride monohydrate 88 mcg x 1 (Dose: 500): Bruk med forsiktighet hos eldre grunnet økt risiko for ortostatisk hypotensjon. Kan påvirke nyrefunksjonen.
- Dabigatran etexilate mesylate 110 mg x 1 (Dose: 200): Dosen bør justeres ved nedsatt nyrefunksjon, og glomerulær filtrasjonsrate bør overvåkes regelmessig.
`,
  }

  console.log("Calling API with chat:", [systemMessage, ...chat])

  try {
    const response = await fetch(`${apiUrl}/api/v1/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorizationToken}`,
      },
      body: JSON.stringify({ chat: [systemMessage, ...chat] }),
    })

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`)
    }

    const reader = response.body?.getReader()
    const decoder = new TextDecoder("utf-8")
    let result
    while (!(result = await reader.read()).done) {
      const chunk = decoder.decode(result.value, { stream: true })
      console.log("Yielding chunk:", chunk)
      yield chunk
    }
  } catch (error) {
    console.error("Error while calling API:", error)
    throw error
  }
}
